const initialState = {
  loading: false,
  totalSupply: 0,
  isAllowed: false,
  walletOfOwner: [],
  ownerUris: [],
  nftData: [],
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        isAllowed: action.payload.isAllowed,
        walletOfOwner: action.payload.walletOfOwner,
        ownerUris: action.payload.ownerUris,
        nftData: action.payload.nftData,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
