import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import loadingSVG from "./assets/Gear-0.2s-100px.svg"
import { db } from "./utils/firebase";
import { ref, push, set } from "firebase/database"
import "semantic-ui-css/semantic.min.css"
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import Faq from "react-faq-component";



const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 75%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const NftContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    @media (max-width: 450px) {
        flex-flow: column wrap;
    }
`;

function App() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: "",
        PRE_MINT_ACTIVE: "",
        ALLOW_LIST_MINT_ACTIVE: "",
        CORE_MINT_ACTIVE: "",
        REDEEM_PRINTS_ACTIVE: "",
        VARIANT_PRE_MINT_ACTIVE: "",
        VARIANT_ALLOW_LIST_MINT: "",
        SOLD_OUT: "",
        MINT_ACTIVE: "",
        MINT_INSTRUCTIONS: ""
    });


    const claimAllowListCoreNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mintAllowList(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                //let transactionHash = err.receipt.transactionHash;
                setFeedback('Sorry, something went wrong please try again later.');
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const claimCoreNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mintAllowList(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const claimAllowListVariantNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);

        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mintAllowListVariant(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                let transactionHash = err.receipt.transactionHash;
                let etherscan = "https://rinkeby.etherscan.io/tx/";
                setFeedback(<StyledLink target={"_blank"} href={etherscan + transactionHash}>
                </StyledLink>);
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `The ${CONFIG.NFT_NAME} is yours! You should now be able to see it on Opensea.io.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 2) {
            newMintAmount = 2;
        }
        setMintAmount(newMintAmount);
    };



    const preMint = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--accent)",
                padding: '15%',
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}>
            <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
                The Sale Hasn't Started Yet.
            </s.TextTitle>
            <s.TextDescription
                style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
                Please check back later.
            </s.TextDescription>
        </s.Container>
    );

    const postCoreMint = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--accent)",
                padding: '15%',
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}>
            <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
                The Core Backyard Roya's Sale Hasn't Started Yet.
            </s.TextTitle>
            <s.TextDescription
                style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
                Please check back later.
            </s.TextDescription>
        </s.Container>
    );

    const walletConnect = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--accent)",
                padding: '15%',
                marginTop: '5%',
                marginBottom: '5%',
                marginLeft: '15%',
                marginRight: '15%',
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}>
            <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                    style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontWeight: 'bold',
                    }}
                >
                    Connect to the {CONFIG.NETWORK.NAME} network using your MetaMask wallet
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledButton
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                    }}
                    style={{ fontFamily: 'MedievalSharp' }}
                >
                    CONNECT
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                    <>
                        <s.SpacerSmall />
                        <s.TextDescription
                            style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                            }}
                        >
                            {blockchain.errorMsg}
                        </s.TextDescription>
                    </>
                ) : null}
            </s.Container>
        </s.Container>
    );

    const allowListCoreMinted = () => (
        <s.Container ai={"center"} jc={"center"}>
            <s.TextTitle
                style={{
                    textAlign: "center",
                    fontSize: 50,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                }}
            >
                {data.totalSupply} / {CONFIG.MAX_CORE_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
                style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                }}
            >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                    {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
            </s.TextDescription>
        </s.Container>
    );

    const allowListCoreMint = () => (
        <s.Container ai={"center"} jc={"center"}>
            {Number(data.totalSupply) >= CONFIG.MAX_CORE_SUPPLY ? (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        The special invitation sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        {CONFIG.MARKETPLACE}
                    </StyledLink>
                </>
            ) : (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        Special Mint Invitation
                    </s.TextTitle>
                    <s.SpacerSmall />
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL}.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        Excluding gas fees.
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }}>
                        {feedback}
                    </s.TextDescription>
                    {claimingNft ?
                        <s.Container
                            flex={1}
                            ai={"center"}
                            style={{ padding: 24 }}>
                            <img src={loadingSVG}></img>
                        </s.Container>
                        : ""
                    }
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledRoundButton style={{ lineHeight: 0.4 }} disabled={claimingNft ? 1 : 0} onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                        }}
                        >
                            -
                        </StyledRoundButton>
                        <s.SpacerMedium />
                        <s.TextDescription
                            style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                            }}
                        >
                            {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                            }}
                        >
                            +
                        </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                claimAllowListCoreNFTs();
                                getData();
                            }}
                            style={{ fontFamily: 'MedievalSharp' }}
                        >
                            {claimingNft ? "BUSY" : "BUY"}
                        </StyledButton>
                    </s.Container>
                </>
            )
            }
        </s.Container >
    );

    const allowListMintContainer = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--accent)",
                padding: '15%',
                marginTop: '5%',
                marginBottom: '5%',
                marginLeft: '15%',
                marginRight: '15%',
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}>
            {data.isAllowed ?
                allowListCoreMinted()
                &&
                allowListCoreMint()
                :
                notAllowed()
            }
        </s.Container>
    );

    const notAllowed = () => (
        <s.Container ai={"center"} jc={"center"} fd={"row"}>
            <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
                Sorry you are not allowed to mint at this time.
            </s.TextTitle>
        </s.Container>
    )

    const coreMinted = () => (
        <s.Container ai={"center"} jc={"center"}>
            <s.TextTitle
                style={{
                    textAlign: "center",
                    fontSize: 50,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                }}
            >
                {data.totalSupply} / {CONFIG.MAX_CORE_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
                style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                }}
            >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                    {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
            </s.TextDescription>
        </s.Container>
    );

    const coreMint = () => (
        <s.Container ai={"center"} jc={"center"}>
            {Number(data.totalSupply) >= CONFIG.MAX_CORE_SUPPLY ? (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        The Backyard Royals Core sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        {CONFIG.MARKETPLACE}
                    </StyledLink>
                </>
            ) : (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL}.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        Excluding gas fees.
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }}>
                        {feedback}
                    </s.TextDescription>
                    {claimingNft ?
                        <s.Container
                            flex={1}
                            ai={"center"}
                            style={{ padding: 24 }}>
                            <img src={loadingSVG}></img>
                        </s.Container>
                        : ""
                    }
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledRoundButton style={{ lineHeight: 0.4 }} disabled={claimingNft ? 1 : 0} onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                        }}
                        >
                            -
                        </StyledRoundButton>
                        <s.SpacerMedium />
                        <s.TextDescription
                            style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                            }}
                        >
                            {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                            }}
                        >
                            +
                        </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                claimCoreNFTs;
                                getData();
                            }}
                            style={{ fontFamily: 'MedievalSharp' }}
                        >
                            {claimingNft ? "BUSY" : "BUY"}
                        </StyledButton>
                    </s.Container>
                </>
            )}
        </s.Container>
    );

    const coreMintContainer = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--accent)",
                padding: '15%',
                marginTop: '5%',
                marginBottom: '5%',
                marginLeft: '15%',
                marginRight: '15%',
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}>
            {
                coreMinted()
                &&
                coreMint()
            }
        </s.Container>
    );

    const allowListVariantMinted = () => (
        <s.Container ai={"center"} jc={"center"}>
            <s.TextTitle
                style={{
                    textAlign: "center",
                    fontSize: 50,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                }}
            >
                {data.totalSupply} / {CONFIG.MAX_CORE_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
                style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                }}
            >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                    {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
            </s.TextDescription>
        </s.Container>
    );

    const allowListVariantMint = () => (
        <s.Container ai={"center"} jc={"center"}>
            {Number(data.totalSupply) >= CONFIG.MAX_CORE_SUPPLY ? (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        The special invitation sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        {CONFIG.MARKETPLACE}
                    </StyledLink>
                </>
            ) : (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        Special Mint Invitation
                    </s.TextTitle>
                    <s.SpacerSmall />
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL}.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        Excluding gas fees.
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }}>
                        {feedback}
                    </s.TextDescription>
                    {claimingNft ?
                        <s.Container
                            flex={1}
                            ai={"center"}
                            style={{ padding: 24 }}>
                            <img src={loadingSVG}></img>
                        </s.Container>
                        : ""
                    }
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledRoundButton style={{ lineHeight: 0.4 }} disabled={claimingNft ? 1 : 0} onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                        }}
                        >
                            -
                        </StyledRoundButton>
                        <s.SpacerMedium />
                        <s.TextDescription
                            style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                            }}
                        >
                            {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                            }}
                        >
                            +
                        </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                claimAllowListVariantNFTs();
                                getData();
                            }}
                            style={{ fontFamily: 'MedievalSharp' }}
                        >
                            {claimingNft ? "BUSY" : "BUY"}
                        </StyledButton>
                    </s.Container>
                </>
            )
            }
        </s.Container >
    );

    const allowListVariantMintContainer = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--accent)",
                padding: '15%',
                marginTop: '5%',
                marginBottom: '5%',
                marginLeft: '15%',
                marginRight: '15%',
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}>
            {isVariantAllowed ?
                allowListVariantMinted()
                &&
                allowListVariantMint()
                :
                notAllowed()
            }
        </s.Container>
    );

    const isVariantAllowed = () => {
        foundCoreNfts = data.walletOfOwner.find(element => element <= 30);
        if (foundCoreNfts) {
            return true
        } else {
            return false;
        }
    };

    const minted = () => (
        <s.Container ai={"center"} jc={"center"}>
            <s.TextTitle
                style={{
                    textAlign: "center",
                    fontSize: 50,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                }}
            >
                {data.totalSupply} / {CONFIG.MAX_CORE_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
                style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                }}
            >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                    {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
            </s.TextDescription>
        </s.Container>
    );

    const mint = () => (
        <s.Container ai={"center"} jc={"center"}>
            {Number(data.totalSupply) >= CONFIG.MAX_CORE_SUPPLY ? (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        The special invitation sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        {CONFIG.MARKETPLACE}
                    </StyledLink>
                </>
            ) : (
                <>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        Special Mint Invitation
                    </s.TextTitle>
                    <s.SpacerSmall />
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL}.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        Excluding gas fees.
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }}>
                        {feedback}
                    </s.TextDescription>
                    {claimingNft ?
                        <s.Container
                            flex={1}
                            ai={"center"}
                            style={{ padding: 24 }}>
                            <img src={loadingSVG}></img>
                        </s.Container>
                        : ""
                    }
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledRoundButton style={{ lineHeight: 0.4 }} disabled={claimingNft ? 1 : 0} onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                        }}
                        >
                            -
                        </StyledRoundButton>
                        <s.SpacerMedium />
                        <s.TextDescription
                            style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                            }}
                        >
                            {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                            }}
                        >
                            +
                        </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                getData();
                            }}
                            style={{ fontFamily: 'MedievalSharp' }}
                        >
                            {claimingNft ? "BUSY" : "BUY"}
                        </StyledButton>
                    </s.Container>
                </>
            )
            }
        </s.Container >
    );

    const mintContainer = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--accent)",
                padding: '15%',
                marginTop: '5%',
                marginBottom: '5%',
                marginLeft: '15%',
                marginRight: '15%',
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}>
            {
                minted()
                &&
                mint()
            }
        </s.Container>
    );

    const [tokenId, setTokenId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [stateAddress, setStateAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [dbSuccess, setDbSuccess] = useState('');

    const send = async (formData) => {
        //e.preventDefault();
        const ownerRef = ref(db, "Owners");
        const newOwnerRef = push(ownerRef);
        const owner = {
            formData,
            walletAddress: blockchain.account
        }

        set(newOwnerRef, owner)
            .then(setDbSuccess(true))
            .catch(alert);
    }

    const { register, handleSubmit, formState: { errors } } = useForm();

    //const nftData = data.nftData;

    const onSubmit = (data) => {
        console.log(data);
        send(data);
    }
    const sendData = () => {
        if (dbSuccess) {
            return (
                <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        Thank you for provding your information to use. We will get your print to you soon.
                    </s.TextDescription>
                </s.Container>
            );
        }
        return (
            <s.Container ai={"center"} jc={"center"}>
                <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                    Because you own at least one Backyard Royal Core NFT you are eligible to receive a print of your NFT. <br /> If you would like to receive a print of your Backyard Royal please fill out this form and select the Backyard Royals you would like prints of.
                </s.TextTitle>
                <s.SpacerSmall />
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>First Name</label>
                        <input
                            placeholder='First Name'
                            type="text"
                            {...register("firstName", { required: true, maxLength: 20 })}
                        />
                    </Form.Field>
                    {errors.firstName && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the First Name</s.TextDescription>}
                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>Last Name</label>
                        <input
                            placeholder='Last Name'
                            type="text"
                            {...register("lastName", { required: true, maxLength: 30 })}
                        />
                    </Form.Field>
                    {errors.lastName && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the Last Name</s.TextDescription>}
                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>Email</label>
                        <input
                            placeholder='Email'
                            type="email"
                            {...register("email", {
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })}
                        />
                    </Form.Field>
                    {errors.email && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the Email</s.TextDescription>}
                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>Phone Number</label>
                        <input
                            placeholder='Phone Number'
                            type="text"
                            {...register("phoneNumber", { required: true })}
                        />
                    </Form.Field>
                    {errors.phoneNumber && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the Phone Number</s.TextDescription>}
                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>Address</label>
                        <input
                            placeholder='Address'
                            type="text"
                            {...register("address1", { required: true })}
                        />
                    </Form.Field>
                    {errors.address1 && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the Address</s.TextDescription>}
                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>Address 2</label>
                        <input
                            placeholder='Address 2'
                            type="text"
                            {...register("address2")}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>City</label>
                        <input
                            placeholder='City'
                            type="text"
                            {...register("city", { required: true })}
                        />
                    </Form.Field>
                    {errors.city && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the City</s.TextDescription>}
                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>State/Provience</label>
                        <input
                            placeholder='State/Provience'
                            type="text"
                            {...register("stateAddress", { required: true })}
                        />
                    </Form.Field>
                    {errors.stateAddress && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the State/Provience </s.TextDescription>}

                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>Zip/Postal Code</label>
                        <input
                            placeholder='Zip/Postal Code'
                            type="text"
                            {...register("zipCode", { required: true })}
                        />
                    </Form.Field>
                    {errors.zipCode && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the Zip/Postal Code</s.TextDescription>}

                    <Form.Field>
                        <label style={{ color: "var(--accent-text)" }}>Country</label>
                        <input
                            placeholder='Country'
                            type="text"
                            {...register("country", { required: true })}
                        />
                    </Form.Field>
                    {errors.country && <s.TextDescription
                        style={{ textAlign: "center", color: "var(--primary-error)" }}
                    >Please check the Country</s.TextDescription>}
                    {(data.loading === false) ? nftCheckboxes() : null}
                    <Button type='submit'
                        style={{ fontFamily: 'MedievalSharp' }}>Submit</Button>
                </Form>
            </s.Container>
        );
    }

    const nftCheckboxes = () => {
        return (
            <>
                <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}>
                    Please select the Backyard Royals you would like prints of.
                </s.TextDescription>
                {
                    data.nftData.map((nft, i) =>
                        <Form.Field>
                            <label>
                                <input
                                    type="checkbox"
                                    value={nft.data.name + i}
                                    name={nft.data.name + i}
                                    {...register(nft.data.name + i)}
                                />
                                <s.TextDescription
                                    style={{ color: "var(--accent-text)" }}
                                >
                                    {nft.data.name + i}
                                </s.TextDescription>
                            </label>
                        </Form.Field>
                    )
                }
            </>

        )
    }


    const nfts = () => {
        let gateway = "https://dweb.link/ipfs/";

        return (
            <s.Container flex={2}
                jc={"center"}
                ai={"center"}
                style={{ width: "100%" }}>
                <s.TextTitle
                    style={{ textAlign: "center", color: "var(--primary-text)" }}
                >
                    Your Backyard Royals
                </s.TextTitle>
                <s.SpacerSmall />
                <NftContainer>
                    {
                        data.nftData.map(nft =>
                            <s.Container flex={1}
                                style={{
                                    padding: 25
                                }}>
                                <img src={gateway + nft.data.image.slice(7)} style={{ width: '100%' }} />
                                <s.TextTitle
                                    style={{ textAlign: "center", color: "var(--primary-text)" }}
                                >
                                    {nft.data.name}
                                </s.TextTitle>
                                <s.TextDescription
                                    style={{ textAlign: "center", color: "var(--primary-text)" }}
                                >
                                    {nft.data.description}
                                </s.TextDescription>
                                <s.SpacerSmall />
                            </s.Container>
                        )
                    }
                </NftContainer>
            </s.Container>
        )
    }

    const redeemPrintsContainer = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--accent)",
                paddingTop: '5%',
                paddingBottom: '5%',
                paddingLeft: '15%',
                paddingRight: '15%',
                marginTop: '5%',
                marginBottom: '5%',
                marginLeft: '15%',
                marginRight: '15%',
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                width: '75%',
            }}>
            {
                sendData()
            }
        </s.Container>
    );

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    const RoadMapContainer = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--primary)",
                paddingLeft: '15%',
                paddingRight: '15%',
                paddingTop: '5%',
                marginLeft: '15%',
                marginRight: '15%'
            }}>
            <s.TextTitle jc={"center"}
                style={{ fontWeight: 'bold' }}>
                ROADMAP
            </s.TextTitle>
            <s.SpacerMedium />
            <s.SpacerMedium />
            <s.TextSubTitle jc={"centert"}
                style={{ fontWeight: 'bold' }}>
                Backyard Royals Printed Art
            </s.TextSubTitle>
            <s.TextDescription jc={"centert"}>
                Because we have a working gallery and print all of our art on canvas, we will be printing your digital collectible on 14x14 canvas stretched over 2inch wooden bars with gallery wire to hang (a $150 value).  Now you will be able to display your collectible on your wall to flex your Royal in the real world.
            </s.TextDescription>
            <s.SpacerMedium />
            <s.TextSubTitle jc={"centert"}
                style={{ fontWeight: 'bold' }}>
                Expansion of the Backyard Royals Metaverse
            </s.TextSubTitle>
            <s.TextDescription jc={"centert"}>
                We have big plans for Backyard Royals going forward and plan to reward our holders as we develop, create, and expand. We will be adding additional Royals to the collection AND additional variations to the current collection, always giving the current Core holder dibs on the variation.
            </s.TextDescription>
            <s.SpacerMedium />
            <s.TextSubTitle jc={"centert"}
                style={{ fontWeight: 'bold' }}>
                Backyard Royals Collab Collection
            </s.TextSubTitle>
            <s.TextDescription jc={"centert"}>
                This collection of diverse NFTs will be based on collaborations, derivatives, and remixes of Backyard Royals with other awesome artists. It will be an ongoing collection with new additions as time goes on.  Expansion details will be announced in Roadmap 2.0 after the Backyard Royals Merchandise and Collab Collection are well established.            </s.TextDescription>
            <s.SpacerMedium />
            <s.TextSubTitle jc={"centert"}
                style={{ fontWeight: 'bold' }}>
                Membership
            </s.TextSubTitle>
            <s.TextDescription jc={"centert"}>
                All sales of Backyard Royals will have a 10% royalty, with 3% going into a “Crown Estate” fund.  As this fund grows and hits specific benchmarks, we will provide a number of Royal Events and Ceremonies.
            </s.TextDescription>.
            <s.SpacerMedium />
            <s.TextSubTitle jc={"centert"}
                style={{ fontWeight: 'bold' }}>
                Discounts for LIFE!
            </s.TextSubTitle>
            <s.TextDescription jc={"centert"}>
                You will automatically become a VIP member of our “Backyards” brand and receive 20% off any of our physical art found at backyardsofkeywest.com (or in-store) and at any of our future expansion locations.  Additionally, as a holder of a Backyard Royal, we will give you wholesale pricing to print ANY of the NFTs you currently hold.  Now you will be able to display ALL of your NFTs on your wall.            </s.TextDescription>.
            <s.SpacerMedium />
        </s.Container>
    );

    const faqData = {
        title: "FAQs",
        rows: [
            {
                title: "What are NFTs?",
                content: "A non-fungible token is a unique and non-interchangeable unit of data stored on a digital ledger. NFTs can be associated with easily-reproducible items such as photos, videos, audio, and other types of digital files as unique items, and use blockchain technology to give the NFT a public proof of ownership",
            },
            {
                title: "How do I purchase Backyard Royals?",
                content: "To buy “mint” the Backyard Royals NFTs, you must have a Metamask Ethereum wallet to buy them with Ethereum cryptocurrency. When the Backyard Royals collection is ready to mint on the drop date, you will be able to connect your Metamask by clicking the mint button at the top of the page to mint your Backyard Royals NFTs.",
            },
            {
                title: "Where do my NFTs go after I buy them?",
                content: "Your Backyard Royals will appear in your Metamask wallet address that you minted them from. You can see them directly in your Metamask wallet or more properly on the Opensea website, where you can also buy and sell specific Toy Boogers on the secondary market with other collectors.,"
            },
            {
                title: "When and where can I buy Backyard Royals?",
                content: "Backyard Royals will be minted from this website as a random blind drop so that each one you get will be a surprise! Pre-sale minting will begin shortly. The Public drop date is still TBD. The Metamask connection will be added to this website for minting shortly before the drop.",
            },
            {
                title: "How do I get on the Pre-Sale Whitelist?",
                content: "Getting whitelisted for the Pre-Sale will be based simply on being early to our discord server, getting ranked, and following the rules. ",
            },
            {
                title: "How many Backyard Royals will there be on the 1st Drop?",
                content: "The Backyard Royals drop will be in two stages, a Core sale and a Variation sale. Our first drop will be 1 of each of the 30 Core Royal designs, this means our first drop will only be the 30 Core Royals. Next, we will drop the 20 Variations of each Core design, giving the current holder first dibs on any of the Variations of their Royal. Since we are doing a limited supply of Royals, be on the lookout for our Pre-Sale drop, so be sure to go to our discord page to get on the whitelist!",
            },
            {
                title: "How many Backyard Royals are there and how much do they cost to mint?",
                content: "There are 30 Core Royals on the 1st drop and 570 Variation Royals on the 2nd.  Prices will be around 0.1 ETH, which will include a physical version of your Core Royal."
            },
        ]
    }

    const faqStyles = {
        bgColor: 'white',
        titleTextColor: "black",
        rowTitleColor: "black",
        //rowContentColor: 'grey',
        // arrowColor: "red",
    };
    const FaqConfig = {
        animate: true,
        arrowIcon: "V",
        tabFocus: true
    };

    const QuestionContainer = () => (
        <s.Container flex={2}
            jc={"center"}
            ai={"center"}
            style={{
                backgroundColor: "var(--secondary-text)",
                paddingLeft: '15%',
                paddingRight: '15%',
                paddingTop: '5%',
                marginLeft: '15%',
                marginRight: '15%'
            }}>
            <Faq
                data={faqData}
                styles={faqStyles}
                config={FaqConfig}
            />
        </s.Container>
    );

    const coreOwner = () => {
        let x = data.walletOfOwner;
        let y = 1;
        let z = CONFIG.MAX_CORE_SUPPLY;
        for (var i = 0; i < x.length; i++) {
            if (x[i] >= y || x[i] <= z) {
                return true;
            }
            else {
                return false;
            }
        }
    };

    useEffect(() => {
        getConfig();
        getData();
    }, [blockchain.account]);


    return (
        <s.Screen>
            <s.Container
                flex={1}
                ai={"center"}
                style={{ padding: 24, backgroundColor: "white", height: 88 }}
            >
                <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
            </s.Container>
            <s.Container
                flex={1}
                ai={"center"}
                style={{ padding: 24, backgroundColor: "var(--primary)", height: 631 }}
                image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
            >
                <s.SpacerSmall />
                <ResponsiveWrapper flex={1} >
                    {CONFIG.PRE_MINT_ACTIVE === true ? preMint() : ""}
                    {((blockchain.account === null || blockchain.smartContract === null) && CONFIG.PRE_MINT_ACTIVE === false) ? walletConnect() : ""}
                    {((blockchain.account !== null || blockchain.smartContract !== null) && CONFIG.ALLOW_LIST_MINT_ACTIVE === true && data.loading === false) ? allowListMintContainer() : ""}
                    {((blockchain.account !== null || blockchain.smartContract !== null) && CONFIG.CORE_MINT_ACTIVE === true && data.loading === false) ? coreMintContainer() : ""}
                    {((blockchain.account !== null || blockchain.smartContract !== null) && CONFIG.REDEEM_PRINTS_ACTIVE === true && data.loading === false && coreOwner === true) ? redeemPrintsContainer() : ""}
                    {((blockchain.account !== null || blockchain.smartContract !== null) && CONFIG.REDEEM_PRINTS_ACTIVE === true && data.loading === false && coreOwner === false) ? postCoreMint(): ""}
                    {((blockchain.account !== null || blockchain.smartContract !== null) && CONFIG.VARIANT_ALLOW_LIST_MINT === true && data.loading === false) ? allowListVariantMintContainer() : ""}
                    {((blockchain.account !== null || blockchain.smartContract !== null) && CONFIG.MINT_ACTIVE === true && data.loading === false) ? mintContainer() : ""}
                </ResponsiveWrapper>

            </s.Container>
            <s.Container
                flex={1}
                ai={"center"}
                style={{
                    backgroundColor: "white"
                }}
            >
                {CONFIG.MINT_INSTRUCTIONS === true ? (
                    <>
                        <s.TextDescription
                            style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                                padding: 24,
                                backgroundColor: "white"
                            }}
                        >
                            Please make sure you are connected to the right network (
                            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
                            Once you make the purchase, you cannot undo this action.
                        </s.TextDescription>
                        <s.SpacerMedium />
                    </>
                ) : null}
                {data.nftData.length > 0 ? nfts() : null}
                <RoadMapContainer />
                <QuestionContainer />
            </s.Container>
        </s.Screen>
    );
}

export default App;
