import axios from 'axios';
// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let isAllowed = await store
        .getState()
        .blockchain.smartContract.methods.isAllowed(account)
        .call();

      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(account)
        .call();

      let ownerUris = [];
      if (walletOfOwner.length > 0) {
        let arrayLength = walletOfOwner.length;
        if (arrayLength > 0) {
          for (let i = 0; i < arrayLength; i++) {
            ownerUris[i] = await store
              .getState()
              .blockchain.smartContract.methods
              .tokenURI(walletOfOwner[i])
              .call();
          }
        }
      }

      let nftData = [];
      if (ownerUris.length > 0) {
        let arrayLength = ownerUris.length;
        let gateway = "https://dweb.link/ipfs/";
        if (arrayLength > 0) {
          for (let i = 0; i < arrayLength; i++) {
            let imgSrc = ownerUris[i].slice(7);
            nftData[i] = await axios.get(gateway + imgSrc);
          }
        }
      }        
      dispatch(
        fetchDataSuccess({
          totalSupply,
          isAllowed,
          walletOfOwner,
          ownerUris,
          nftData
        })
      );
      console.log(isAllowed);
      console.log(ownerUris);
      console.log(nftData);
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
