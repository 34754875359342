import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"

    const firebaseConfig = {
        apiKey: "AIzaSyDTZMvc4qbJ9UL38kz-tK4yXAsEzSWU3Ps",
        authDomain: "backyard-test-12432.firebaseapp.com",
        databaseURL: "https://backyard-test-12432-default-rtdb.firebaseio.com",
        projectId: "backyard-test-12432",
        storageBucket: "backyard-test-12432.appspot.com",
        messagingSenderId: "651406102258",
        appId: "1:651406102258:web:af4eba3f4a7f2522ff8a6b"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);    
export { db }